body {
  margin-left: 10px;
  margin-right: 10px;
  margin: 0px;
  line-height: 1;
  font-family: 'Playfair Display', serif;
}


/*=================
Main section Start
===================*/

.main_section_images {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: url('https://images.unsplash.com/photo-1519735777090-ec97162dc266?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=815&q=80'); */
  background-size: cover;
  opacity: 0.4;
  content: "";
  z-index: -1;
}
#main_section {
  position: relative;
  margin-top: 122px;
  margin-left: 3%;
  margin-right:3%;
  width: 94%;
  height: 100%;
  /* background: url('https://images.unsplash.com/photo-1519735777090-ec97162dc266?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=815&q=80'); */
  background-size: cover;
  opacity: 1.0;
  content: "";
  z-index: -1;
  float: left;
  display: block;
  clear: both;
  margin-bottom: 30px;
}


/*=================
Main section End
===================*/

/*=================
Companies start
===================*/
#companies {
  position: relative;
  margin: auto;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  clear: both;
}
#companies h1 {
  text-align: center;
}
.line {
  width: 200px;
  height: 4px;
  background-color: #826363;
  margin: auto;
  border-radius: 10%;
}
.line_short {
    width: 100px;
    height: 1px;
    background-color: #826363;
    margin: auto;
    border-radius: 20%;
}
.ln {
  text-align: center;
  background-color: #826363;
  color: white;
  border-radius: 100%;
  padding: 2px;
}
.companies_single {
  display: inline-block;
  width: 100%;
  /* background-color: #826363; */
}
#companies .c_body {
  display: table;
  margin: 0 auto;
  /* background-color: #826363; */
  width: 100%;
  height: fit-content;
}
#companies .c_body .single {
  float: left;
  width: 31%;
  background-color: #826363;
  margin: 1.1%;
  padding: 1px;
  box-shadow: 5px 5px 7.5px #82636390;
  transition: all .1s linear;
  cursor: pointer;
}
#companies .c_body .single:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  box-shadow: 10px 10px 15px #82636390;
}
#companies img {
  width: 100%;
  margin: 0 auto;
  display: block;
}
.forever-single {
  float: left;
  width: 31%;
  background-color: #826363;
  margin: 1.1% 34.3%;
  padding: 1px;
  box-shadow: 5px 5px 7.5px #82636390;
  transition: all .1s linear;

}
.forever-single:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 15px #82636390;
}
@media screen and (max-width : 900px) {
  #companies .c_body .single {
    width: 94%;
    margin: 3%;
  }
  .empty_single {
    width: 0%;
    background-color: #fff;
    margin: 0px;
    padding: 0px;
    box-shadow: none;
  }
  .forever-single {
    float: left;
    width: 94%;
    background-color: #826363;
    margin: 1.1% 3%;
    padding: 1px;
    box-shadow: 0 0 .7em rgba(0, 0, 0, .5);
  }
  #companies h1 {
    font-size: 24px;
  }
  #companies .line {
    width: 150px;
    height: 1px;
    background-color: #826363;
    margin: auto;
    border-radius: 20%;
}
}

/*=================
Companies section End
===================*/

/*=================
Partners start
===================*/
#partners h1 {
  text-align: center;
}
.parners body {
  display: block;
}
#partners .body .single {
  float: left;
  width: 30%;
  background-image: url('https://media-exp1.licdn.com/dms/image/C511BAQHDmQrN7RC_fw/company-background_10000/0/1553433731336?e=2159024400&v=beta&t=-2KZtDePjrWy5Iave68lKvqGJqUwYffwXQJg-QQD3Dg');
  background-size: cover;
  height: 200px;
  background-color: #826363;
  margin: 10px;
}
/*=================
Partners section End
===================*/
.single::before,
.single::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform 0.3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: "";
  pointer-events: none;
}
.single::before {
  transform-origin: left top;
}
.single::after {
  transform-origin: right bottom;
}
.single:hover::before,
.single:hover::after,
.single:focus::before,
.single:focus::after {
  transform: scale3d(1, 1, 1);
}