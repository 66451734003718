
/*=================
Navbar Start
===================*/

.my_navbar {
    background-color: #826363;
    background-color: white;
    position: fixed ;
    width: 100%;
    opacity: 100;
    margin-top: 0px;
    padding-top: 0px;
    box-shadow: 0 0 .5em rgba(0, 0, 0, .5);
    display: block;
    z-index: 100;
    margin-top: 0px;
    float: left;
  }
  .my_navbar div .main_logo {
    width: 288px;
    text-align: center;
  }
  .my_navbar div ul {
    float: right;
    display: flex;
    align-items: center;
    list-style: none;
    padding-right: 20px;
    margin-right: 40px;
    z-index: 100;
  }
  .my_navbar div ul li {
    margin-left: 20px;
    z-index: 100;
  }
  .my_navbar div ul li svg:hover {
    transform: scale(1.25);
    /* box-shadow: 10px 10px 15px #82636390; */
    transition: all .2s linear;
  }
  .my_navbar ul li .icon_shade {
    text-shadow: 1px 1px 1px #000;
  }
  
  #company_name {
    font-family: 'Titillium Web', sans-serif;
    text-align: left;
    line-height: 52px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(26, 23, 23);
    margin-left: 10px;
    display: block;
    font-family: 'Titillium Web', sans-serif;
    z-index: 0;
  }
  #my_nav .social {
    display: none;
  }
  @media screen and (min-width : 490px) {
    .my_navbar {
      padding-left: 50px;
      padding-right: 50px;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%;
    }
    #my_nav .social {
      display: block;
    }
  }

.rotate {
    animation: rotation 2s infinite linear; 
}
  @keyframes rotation {
    33% {
      transform: rotate(0deg);
    }
    66% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(180deg);
    }
  }
  .navigation {
    top: 122px;
    right: 80px;
    position: "fixed";
    width: 300px;
    background: #000000E0;
    z-index: 100;
    visibility: hidden;
  }
  .menu:hover {
    background-color: #40667d;
  }
  .menu:hover .navig{
    visibility: visible;
  }
  /* dd */
  
  
  .dropdown .dropbtn {
    font-size: 16px;  
    border: none;
    outline: none;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    z-index: 100;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #00000090;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 100;
    right: -50px;
  }
  
  .dropdown-content a {
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: #40667d;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  /* Animations */
  
  /*=================
  Navbar End
  ===================*/