/* Three columns side by side */
.column {
  float: left;
  width: 33.33%;
  margin-bottom: 16px;
  padding: 0 8px;
  background-color: white;
}

/* Display the columns below each other instead of side by side on small screens */
@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}

/* Add some shadows to create a card effect */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  box-shadow: 5px 5px 7.5px 0px #82636390;
  transition: all .1s linear;
}
.text-only {
  background-color: white;
  color: black;
  padding: 50px 0;
}
.card:hover {
    box-shadow: 10px 10px 15px #82636390;
}


/* Some left and right padding inside the container */
.container {
  padding: 0 16px;
  justify-content: space-around;
}

/* Clear floats */
.container::after,
.row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
  font-size: 14px;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

/* Extra */
#company_profile {
  padding-top: 150px;
  position: relative;
  width: 60%;
  margin: 0 auto;
  font-family: "Merriweather", serif;
}
#company_profile h2 {
  font-size: 20px;
  font-weight: 800;
  font-family: "Merriweather", serif;
}
p {
  font-size: 14px;
  padding-bottom: 5px;
  margin-bottom: 0px;
}
.row {
  justify-content: center;
}

