
/*=================
Footer start
===================*/
#footer {
    margin-top: 10px;
    background-color: #826363D0;
    position: relative;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    display: table;
}
#footer ul {
  margin-bottom: 0px;
}

#footer .contacts {
    float:left;
    width: 30%;
}
#footer .links {
    float : right;
    width: 30%;
    margin-top: -300px;
}
#footer p  {
    margin-left: 20px;
    margin-right: 20px;
}
#footer h2 {
    margin-left: 0px;
    margin-right: 0px;
}
/* From tutplus */
/* Generic styling */
* {
  box-sizing: border-box;
  font-family: 'Playfair Display', serif;
  margin: 0;
  padding: 0;
  }
ul {
  list-style: none;
  padding-left: 0;
}
footer {
  background-color: #826363;
  background-color: #8e99f3;
  background-color: #40667d;
  color: #bbb;
  line-height: 1.3;
}
footer a {
  text-decoration: none;
  color: #000;
}
footer li {
  text-decoration: none;
  color: #000;
}
a:hover {
  text-decoration: underline;
  color: #fff;
}
.ft-title {
  color: #000;
  font-family: "Merriweather", serif;
  font-size: 1.375rem;
  padding-bottom: 0.625rem;
}
/* Sticks footer to bottom */
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  
}
.container {
  flex: 1;
}
/* Footer main */
.ft-main {
  padding: 0rem 7%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media only screen and (min-width: 29.8125rem /* 477px */) {
  .ft-main {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 77.5rem /* 1240px */) {
  .ft-main {
    justify-content: space-between;
  }
}
.ft-main-item {
  padding: 1.25rem;
  min-width: 12.5rem;
}

/* Footer main | Newsletter form */
form {
  display: flex;
  flex-wrap: wrap;
}
input[type="email"] {
  border: 0;
  padding: 0.625rem;
  margin-top: 0.3125rem;
}
input[type="submit"] {
  background-color: #00d188;
  color: #fff;
  cursor: pointer;
  border: 0;
  padding: 0.625rem 0.9375rem;
  margin-top: 0.3125rem;
}
/* Footer social */
.ft-social {
  padding: 0 0px 0px;
}
.ft-social-list {
  display: flex;
  justify-content: center;
  border-top: 1px #777 solid;
  padding-top: 0px;
}
.ft-social-list li {
  margin: 0.5rem;
  font-size: 1.25rem;
}
/* Footer legal */
.ft-legal {
  padding: 0.9375rem 1.875rem;
  background-color: #82636380;
  background-color: #8e99f380; 
  background-color: #40667d;
}
.ft-legal-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.ft-legal-list li {
  margin: 0.125rem 0.625rem;
  white-space: nowrap;
}
/* one before the last child */
.ft-legal-list li:nth-last-child(2) {
    flex: 1;
}

/*=================
Footer End
===================*/