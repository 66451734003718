.section-title {
  text-align: center;
}

#parnership img {
  height: 250px;
  width: 100%;
  border: #82636390;
  border-style: solid;
  border-width: 1px;
}

.item {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.item-card {
  transition: 0.5s;
  cursor: pointer;
}
.item-card-title {
  font-size: 15px;
  transition: 1s;
  cursor: pointer;
}
.item-card-title i {
  font-size: 15px;
  transition: 1s;
  cursor: pointer;
  color: #ffa710;
}
.card-title i:hover {
  transform: scale(1.25) rotate(100deg);
  color: #18d4ca;
}
.card:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 15px #82636390;
}
.card-text {
  height: 80px;
}

.card::before,
.card::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform 0.3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: "";
  pointer-events: none;
}
.card::before {
  transform-origin: left top;
}
.card::after {
  transform-origin: right bottom;
}
.card:hover::before,
.card:hover::after,
.card:focus::before,
.card:focus::after {
  transform: scale3d(1, 1, 1);
}
